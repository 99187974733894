










































































































































import { SelectOption } from "@/plugins/vuetify"
import {
    DayOfWeek,
    dayOfWeekFormatter,
    MonthMode,
    numberToOrderedDayOfWeek,
    orderedDayOfWeekFormatter,
    RecurrenceSetting,
    RecurrenceType,
    calculateRecurrence,
} from "@/utilities/Recurrence"
import {
    computed,
    defineComponent,
    reactive,
    toRefs,
} from "@vue/composition-api"
import dayjs from "dayjs"
import { timeFormatter } from "@/utilities/Formatter"

export default defineComponent({
    name: "RecurrenceInput",
    components: {
        DateTimeInput: () => import("./DateTimeInput.vue"),
    },
    props: {
        errorMessages: [String, Array],
        label: { type: String, default: "" },
        value: {
            type: Object as () => RecurrenceSetting,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        startTime: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            menu: false,
            isTimePicker: false,
            showCalculateResult: false,
            calculateResult: [] as string[],
        })

        const type = computed({
            get: () => props.value.recurrenceType ?? null,
            set: (value) => emitInput({ recurrenceType: value }),
        })

        const interval = computed({
            get: () => props.value.interval ?? 1,
            set: (value) => emitInput({ interval: value }),
        })

        const timesInDay = computed({
            get: () => props.value.timesInDay ?? [],
            set: (value) => emitInput({ timesInDay: value }),
        })

        const dayOfWeeks = computed({
            get: () => props.value.dayOfWeeks ?? [],
            set: (value) => emitInput({ dayOfWeeks: value }),
        })

        const monthMode = computed({
            get: () => props.value.monthMode ?? MonthMode.OrderedDayOfWeek,
            set: (value) => emitInput({ monthMode: value }),
        })

        const daysInMonth = computed({
            get: () => props.value.daysInMonth ?? [],
            set: (value) => emitInput({ daysInMonth: value }),
        })

        const orderedDayOfWeek = computed({
            get: () =>
                (props.value.orderedDayOfWeek ?? []).map(
                    (item) => item.order * 7 + item.dayOfWeek
                ),
            set: (value) => {
                const result = value.map((i) => numberToOrderedDayOfWeek(i))
                emitInput({ orderedDayOfWeek: result })
            },
        })

        const endTime = computed({
            get: () =>
                props.value.endTime ??
                dayjs().startOf("months").add(1, "months").toISOString(),
            set: (value) => emitInput({ endTime: value }),
        })

        function emitInput(value: Partial<RecurrenceSetting>) {
            const payload: RecurrenceSetting = {
                recurrenceType:
                    value.recurrenceType !== undefined
                        ? value.recurrenceType
                        : type.value,
                interval: value.interval ?? interval.value,
                endTime: value.endTime ?? endTime.value,
                timesInDay: value.timesInDay ?? timesInDay.value,
                monthMode: value.monthMode ?? monthMode.value,
                dayOfWeeks: value.dayOfWeeks ?? dayOfWeeks.value,
                daysInMonth: value.daysInMonth ?? props.value.daysInMonth,
                orderedDayOfWeek:
                    value.orderedDayOfWeek ?? props.value.orderedDayOfWeek,
            }
            emit("input", payload)
        }

        const typeOptions = [
            {
                text: "不循環",
                value: null,
            },
            {
                text: "日",
                value: RecurrenceType.Daily,
            },
            {
                text: "週",
                value: RecurrenceType.Weekly,
            },
            {
                text: "月",
                value: RecurrenceType.Monthly,
            },
        ]

        const timesInDayOptions: SelectOption[] = [...Array(6 * 24).keys()].map(
            (i) => {
                const minutes = i * 10
                const hour = Math.floor(i / 6)
                const minute = (i % 6) * 10
                return {
                    text: `${hour.toString().padStart(2, "0")}:${minute
                        .toString()
                        .padStart(2, "0")}`,
                    value: minutes,
                }
            }
        )

        const hasDayOfWeekOption = computed(
            () => type.value === RecurrenceType.Weekly
        )

        const hasUseDaysInMonthOption = computed(
            () => type.value === RecurrenceType.Monthly
        )

        const hasDaysInMonthOption = computed(
            () =>
                type.value === RecurrenceType.Monthly &&
                monthMode.value === MonthMode.DaysInMonth
        )

        const dayOfWeekOptions: SelectOption[] = [
            {
                text: "星期日",
                value: DayOfWeek.Sunday,
            },
            {
                text: "星期一",
                value: DayOfWeek.Monday,
            },
            {
                text: "星期二",
                value: DayOfWeek.Tuesday,
            },
            {
                text: "星期三",
                value: DayOfWeek.Wednesday,
            },
            {
                text: "星期四",
                value: DayOfWeek.Thursday,
            },
            {
                text: "星期五",
                value: DayOfWeek.Friday,
            },
            {
                text: "星期六",
                value: DayOfWeek.Saturday,
            },
        ]
        const daysInMonthOptions: SelectOption[] = [...Array(28).keys()].map(
            (i) => {
                return {
                    text: `${i + 1} 日`,
                    value: i + 1,
                }
            }
        )

        const hasOrderedDayOfWeekOption = computed(
            () =>
                type.value === RecurrenceType.Monthly &&
                monthMode.value === MonthMode.OrderedDayOfWeek
        )

        const orderedDayOfWeekOptions: SelectOption[] = [
            ...Array(28).keys(),
        ].map((i) => {
            const orderedDayOfWeek = numberToOrderedDayOfWeek(i)
            return {
                text: orderedDayOfWeekFormatter(orderedDayOfWeek),
                value: i,
            }
        })

        const text = computed(() => {
            const timesInDayText = timesInDay.value.length
                ? `的 ${timesInDay.value
                      .map((minutes) => {
                          const hour = Math.floor(minutes / 60)
                          const minute = minutes % 60
                          return `${hour.toString().padStart(2, "0")}:${minute
                              .toString()
                              .padStart(2, "0")}`
                      })
                      .join(", ")}`
                : ""

            const endTimeText = dayjs(endTime.value).format("YYYY-MM-DD HH:mm")
            switch (type.value) {
                case null:
                    return "不循環"
                case RecurrenceType.Daily:
                    return `每 ${interval.value} 日${timesInDayText}，直到 ${endTimeText}`
                case RecurrenceType.Weekly:
                    const dayOfWeekText = dayOfWeeks.value.length
                        ? `的${dayOfWeeks.value
                              .map((dayOfWeek) => dayOfWeekFormatter(dayOfWeek))
                              .join(", ")}`
                        : ""
                    return `每 ${interval.value} 週${dayOfWeekText}${timesInDayText}，直到 ${endTimeText}`
                case RecurrenceType.Monthly:
                    if (monthMode.value === MonthMode.DaysInMonth) {
                        const daysInMonthText = daysInMonth.value.length
                            ? `的 ${daysInMonth.value
                                  .map((i) => `${i} 日`)
                                  .join(", ")}`
                            : "的同日"
                        return `每 ${interval.value} 月${daysInMonthText}${timesInDayText}，直到 ${endTimeText}`
                    } else {
                        const orderedDayOfWeekText = orderedDayOfWeek.value
                            .length
                            ? `的${orderedDayOfWeek.value
                                  .map((i) => {
                                      const orderedDayOfWeek =
                                          numberToOrderedDayOfWeek(i)
                                      return orderedDayOfWeekFormatter(
                                          orderedDayOfWeek
                                      )
                                  })
                                  .join(", ")}`
                            : "的同週次星期"
                        return `每 ${interval.value} 月${orderedDayOfWeekText}${timesInDayText}，直到 ${endTimeText}`
                    }

                default:
                    return "--"
            }
        })

        const canCalculate = computed(
            () => !!props.value.recurrenceType && !!props.startTime
        )

        async function calculate() {
            if (
                !props.value.recurrenceType ||
                !props.value.interval ||
                !props.startTime ||
                !props.value.endTime
            )
                return

            state.calculateResult = await calculateRecurrence({
                setting: {
                    type: props.value.recurrenceType,
                    interval: props.value.interval,
                    startTime: props.startTime,
                    endTime: props.value.endTime,
                    timesInDay: props.value.timesInDay?.length
                        ? props.value.timesInDay
                        : null,
                    dayOfWeeks: props.value.dayOfWeeks?.length
                        ? props.value.dayOfWeeks
                        : null,
                    monthMode: props.value.monthMode,
                    daysInMonth: props.value.daysInMonth?.length
                        ? props.value.daysInMonth
                        : null,
                    orderedDayOfWeek: props.value.orderedDayOfWeek?.length
                        ? props.value.orderedDayOfWeek
                        : null,
                },
            })
            state.showCalculateResult = true
        }

        function blur() {
            emit("blur")
        }

        function clear() {}

        return {
            ...toRefs(state),
            blur,
            clear,
            text,
            typeOptions,
            type,
            interval,
            timesInDay,
            dayOfWeeks,
            endTime,
            timesInDayOptions,
            dayOfWeekOptions,
            hasDayOfWeekOption,
            hasUseDaysInMonthOption,
            daysInMonthOptions,
            daysInMonth,
            hasDaysInMonthOption,
            monthMode,
            orderedDayOfWeekOptions,
            hasOrderedDayOfWeekOption,
            orderedDayOfWeek,
            MonthMode,
            canCalculate,
            calculate,
            timeFormatter,
        }
    },
})
