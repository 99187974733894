






































































































import { defineComponent, reactive, toRefs, watch } from "@vue/composition-api"
import UserPagingSelect from "@/components/UserPagingSelect.vue"
import PagingSelect from "@/components/PagingSelect.vue"
import DateTimeInput from "@/components/DateTimeInput.vue"
import RecurrenceInput from "@/components/RecurrenceInput.vue"
import {
    getMissionTemplates,
    getMissionTemplate,
    MissionTemplateDTO,
} from "@/services/MissionTemplatesService"
import {
    missionTypeFormatter,
    missionTypes,
    postMission,
} from "@/services/MissionsService"
import dayjs from "dayjs"
import {
    warningDialog,
    DialogButtonType,
    closeDialog,
    errorDialog,
    infoDialog,
} from "@/services/DialogService"
import {
    MonthMode,
    RecurrenceSetting,
    RecurrenceType,
} from "@/utilities/Recurrence"
import { HTTPError } from "ky"
import { router } from "@/services/Router"
import { env } from "@/env"

export default defineComponent({
    name: "MissionCreator",
    components: {
        UserPagingSelect,
        DateTimeInput,
        RecurrenceInput,
        PagingSelect,
    },
    setup() {
        const state = reactive({
            templateId: null as string | null,
            template: null as MissionTemplateDTO | null,
            startTime: dayjs().startOf("day").add(8, "hour").toISOString(),
            overdueTime: dayjs().startOf("day").add(32, "hour").toISOString(),
            recurrenceSetting: {} as RecurrenceSetting,
        })

        const missionTypeOptions = missionTypes.map((t) => ({
            text: missionTypeFormatter(t),
            value: t,
        }))

        watch(
            () => state.templateId,
            async (id) => {
                if (!id) return
                state.template = await getMissionTemplate(id)
                if (state.template!.duration)
                    state.overdueTime = dayjs(state.startTime)
                        .add(state.template!.duration, "minute")
                        .toISOString()
            }
        )

        watch(
            () => state.startTime,
            (value, old) => {
                const oldTime = dayjs(old)
                const newTime = dayjs(value)
                const diffInMinute = newTime.diff(oldTime, "minute")
                state.overdueTime = dayjs(state.overdueTime)
                    .add(diffInMinute, "minute")
                    .toISOString()
            }
        )

        async function missionTemplateIdSearch(ids: string[]) {
            const { items } = await getMissionTemplates({
                ids,
            })
            return items.map((item) => ({
                value: item.id,
                text: item.subject,
            }))
        }

        async function missionTemplateSubjectSearch(
            keyword: string,
            skip: number,
            take: number
        ) {
            const { total, items } = await getMissionTemplates({
                keyword,
                skip,
                take,
            })
            return {
                total,
                items: items.map((item) => ({
                    value: item.id,
                    text: item.subject,
                })),
            }
        }

        async function submit() {
            if (!state.template) {
                warningDialog("請選擇任務範本")
                return
            }
            infoDialog("處理中", "請稍後", DialogButtonType.None)
            try {
                await postMission({
                    type: state.template.type,
                    subject: state.template.subject,
                    startTime: state.startTime,
                    overdueTime: state.overdueTime!,
                    recurrenceType: state.recurrenceSetting.recurrenceType,
                    interval: state.recurrenceSetting.interval,
                    endTime: state.recurrenceSetting.endTime,
                    timesInDay: state.recurrenceSetting.timesInDay?.length
                        ? state.recurrenceSetting.timesInDay
                        : null,
                    dayOfWeeks:
                        state.recurrenceSetting.recurrenceType ===
                            RecurrenceType.Weekly &&
                        state.recurrenceSetting.dayOfWeeks?.length
                            ? state.recurrenceSetting.dayOfWeeks
                            : null,
                    monthMode: state.recurrenceSetting.monthMode,
                    daysInMonth:
                        state.recurrenceSetting.monthMode ===
                            MonthMode.DaysInMonth &&
                        state.recurrenceSetting.daysInMonth?.length
                            ? state.recurrenceSetting.daysInMonth
                            : null,
                    orderedDayOfWeek:
                        state.recurrenceSetting.monthMode ===
                            MonthMode.OrderedDayOfWeek &&
                        state.recurrenceSetting.orderedDayOfWeek?.length
                            ? state.recurrenceSetting.orderedDayOfWeek
                            : null,
                    description: state.template.description,
                    managerIds: state.template.managerIds,
                    assigneeIds: state.template.assigneeIds,
                    cooperatorIds: state.template.cooperatorIds,
                    fields: state.template.missionFieldTemplates!,
                    signatureFields:
                        state.template.missionSignatureFieldTemplates,
                })
                closeDialog()
                router.back()
            } catch (error) {
                closeDialog()
                if (
                    error instanceof HTTPError &&
                    error.response.status === 400
                ) {
                    warningDialog("無法建立任務", await error.response.json())
                    return
                }
                console.error(error)
                errorDialog("發生不預期錯誤，請聯絡網站維護人員。")
            }
        }

        return {
            ...toRefs(state),
            missionTemplateIdSearch,
            missionTemplateSubjectSearch,
            missionTypeFormatter,
            missionTypeOptions,
            submit
        }
    },
})
