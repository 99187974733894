

































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
} from "@vue/composition-api"
import dayjs from "dayjs"

export default defineComponent({
    name: "DateTimeInput",
    props: {
        allowedDates: { type: Function, default: null },
        errorMessages: [String, Array],
        label: { type: String, default: "" },
        mode: {
            type: String as () => "date" | "month" | "time",
            default: "date",
            validator: (value: unknown) => {
                return ["date", "month", "time"].includes(value as any)
            },
        },
        value: {
            type: String,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        max: String,
        min: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            menu: false,
            activeTab: 0,
        })

        const maxDate = computed(() => {
            if (!props.max) return ""
            return props.mode === "month"
                ? dayjs(props.max).format("YYYY-MM")
                : dayjs(props.max).format("YYYY-MM-DD")
        })

        const minDate = computed(() => {
            if (!props.min) return ""
            return props.mode === "month"
                ? dayjs(props.min).format("YYYY-MM")
                : dayjs(props.min).format("YYYY-MM-DD")
        })

        const timeInDayjs = computed(() =>
            !!props.value ? dayjs(props.value) : null
        )

        const text = computed(() => {
            if (!timeInDayjs.value) return ""

            switch (props.mode) {
                case "month":
                    return timeInDayjs.value.format("YYYY-MM")
                case "date":
                    return timeInDayjs.value.format("YYYY-MM-DD")
                case "time":
                    return timeInDayjs.value.format("YYYY-MM-DD HH:mm")
                default:
                    break
            }
        })

        const normalizedDate = computed({
            get: () => {
                if (!timeInDayjs.value) return ""

                return props.mode === "month"
                    ? timeInDayjs.value.format("YYYY-MM")
                    : timeInDayjs.value.format("YYYY-MM-DD")
            },
            set: (value) => {
                state.menu = false
                if (value === "") emit("input", null)
                else
                    emit(
                        "input",
                        dayjs(
                            `${value} ${timeInDayjs.value?.format("HH:mm")}`
                        ).toISOString()
                    )
            },
        })

        const normalizedTime = computed({
            get: () => {
                if (!timeInDayjs.value) return ""
                return timeInDayjs.value.format("HH:mm")
            },
            set: (value) => {
                if (value === "") emit("input", null)
                else
                    emit(
                        "input",
                        dayjs(
                            `${timeInDayjs.value?.format(
                                "YYYY-MM-DD"
                            )} ${value}`
                        ).toISOString()
                    )
            },
        })

        function blur() {
            emit("blur")
        }

        function clear() {
            normalizedDate.value = ""
        }

        return {
            ...toRefs(state),
            normalizedDate,
            blur,
            clear,
            maxDate,
            minDate,
            normalizedTime,
            text,
        }
    },
})
