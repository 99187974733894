



















































































import {
    defineComponent,
    reactive,
    watch,
    toRefs,
    PropType,
    computed,
} from "@vue/composition-api"

export default defineComponent({
    name: "PagingSelect",
    props: {
        errorMessages: [String, Array],
        label: { type: String, default: "" },
        value: {
            type: [Array as () => string[], String],
            default: null,
        },
        /** ID集合查詢方法 */
        idSearchFunc: {
            type: Function as PropType<
                (ids: string[]) => Promise<{ value: string; text: string }[]>
            >,
            required: true,
        },
        /** 關鍵字查詢方法 */
        keywordSearchFunc: {
            type: Function as PropType<
                (
                    keyword: string,
                    skip: number,
                    take: number
                ) => Promise<{
                    total: number
                    items: { value: string; text: string }[]
                }>
            >,
            required: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        itemsPerPage: {
            type: Number,
            default: 15,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            showDialog: false,
            selectedItems: [] as { value: string; text: string }[],
            innerSelectedIds: [] as string[],
            items: [] as { value: string; text: string }[],
            total: 0,
            keyword: "",
            page: 1,
        })

        const text = computed(() =>
            state.selectedItems.map((i) => i.text).join(", ")
        )

        const totalPages = computed(() =>
            Math.ceil(state.total / props.itemsPerPage)
        )

        watch(
            () => props.value,
            async (value) => {
                if (Array.isArray(value)) {
                    state.selectedItems = await props.idSearchFunc(value)
                    state.innerSelectedIds = value
                } else if (value !== null) {
                    state.selectedItems = await props.idSearchFunc([value])
                    state.innerSelectedIds = [value]
                } else {
                    state.selectedItems = []
                    state.innerSelectedIds = []
                }
            },
            {
                immediate: true,
            }
        )

        async function loadData(reSearch?: boolean) {
            if (reSearch) state.page = 1
            const skip = props.itemsPerPage * (state.page - 1)
            const take = props.itemsPerPage
            const { total, items } = await props.keywordSearchFunc(
                state.keyword,
                skip,
                take
            )
            state.total = total
            state.items = items
        }

        loadData()

        function clear() {
            if (props.multiple) state.innerSelectedIds = []
            else {
                state.innerSelectedIds = []
                state.showDialog = false
                emit("input", null)
            }
        }

        function confirm() {
            state.showDialog = false
            emit("input", state.innerSelectedIds)
        }

        function openDialog() {
            state.showDialog = true
        }

        function select(value: string) {
            state.innerSelectedIds = [value]
            state.showDialog = false
            emit("input", value)
        }

        return {
            ...toRefs(state),
            blur,
            clear,
            confirm,
            text,
            totalPages,
            openDialog,
            loadData,
            select,
        }
    },
})
