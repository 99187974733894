import ky from "ky"
import { accessToken } from "@/services/AuthService"
import { env } from "@/env"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
} from "@/services/DialogService"
import { buildParms } from "@/utilities/SearchParmsBuilder"
import { login } from "@/services/AuthService"
import { Cache } from "@/utilities/Cache"

const api = ky.create({
    prefixUrl: env.AUTHORITY,
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set(
                    "Authorization",
                    `Bearer ${accessToken.value}`
                )
            },
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if (response.status === 401) {
                    const { button } = await errorDialog(
                        "身份驗證失敗，請重新登入",
                        undefined,
                        DialogButtonType.YesNo
                    )
                    if (button === ButtonResult.Yes) login()
                }
            },
        ],
    },
})

export interface UserDTO {
    id: string
    account: string
    canLogin: boolean
    email: string | null
    name: string
    teams: UserDTO[] | null
    isReserved: boolean
    isDisabled: boolean
}

/** 查詢使用者 */
export function getUsers(
    query: {
        skip?: number | null
        take?: number | null
        ids?: string[] | null
        keyword?: string | null
        accounts?: string[] | null
        inIdentity?: string | null
        notInIdentity?: string | null
        notDisabled?: boolean
    } = {}
) {
    return api
        .get("api/Users", {
            searchParams: buildParms(query),
        })
        .json<{
            total: number
            items: UserDTO[]
        }>()
}

/** 取得使用者 */
export function getUser(id: string) {
    return api.get(`api/Users/${id}`).json<UserDTO>()
}

/** 修改使用者基本資料 */
export function putUserBasicInfo(
    id: string,
    input: {
        account: string
        name: string
        email?: string | null
    }
) {
    return api.put(`api/Users/${id}/BasicInfo`, {
        json: input,
    })
}

/** 修改使用者所在團隊 */
export function putUserTeams(id: string, teamIds: string[]) {
    return api.put(`api/Users/${id}/Teams`, {
        json: teamIds,
    })
}

/** 修改使用者密碼 */
export function putUserPassword(
    id: string,
    currentPassword: string,
    newPassword: string
) {
    return api.put(`api/Users/${id}/Password`, {
        json: {
            currentPassword,
            newPassword,
        },
    })
}

export const userCache = new Cache<UserDTO>(
    async (ids) => {
        const { items } = await getUsers({
            ids,
        })
        return items
    },
    (item) => item.id
)

export const accountCache = new Cache<UserDTO>(
    async (ids) => {
        const { items } = await getUsers({
            accounts: ids,
        })
        return items
    },
    (item) => item.account
)
