import ky from "ky"
import { accessToken } from "@/services/AuthService"
import { env } from "@/env"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
} from "@/services/DialogService"
import { login } from "@/services/AuthService"
import { buildParms } from "@/utilities/SearchParmsBuilder"
import { MissionRuleViewModel, MissionType } from "./MissionsService"
import xlsx from "xlsx"
import dayjs from "dayjs"
import { missionTypeFormatter } from "@/services/MissionsService"
import { getUsers } from "@/services/UsersClient"
import { uniq } from "lodash"
import { autofitColumns } from "@/utilities/XLSXParser"

const api = ky.create({
    prefixUrl: env.MISSION_SERVICE,
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set(
                    "Authorization",
                    `Bearer ${accessToken.value}`
                )
            },
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if (response.status === 401) {
                    const { button } = await errorDialog(
                        "身份驗證失敗，請重新登入",
                        undefined,
                        DialogButtonType.YesNo
                    )
                    if (button === ButtonResult.Yes) login()
                }
            },
        ],
    },
})

export enum FieldType {
    NormalAbnormal,
    Number,
    Select,
    YesNo,
    Text,
    MutilineText,
}
export const fieldTypes = Object.values(FieldType).filter(
    (v) => typeof v !== "string"
) as FieldType[]
export const vaildFieldTypeText = fieldTypes.map((t) => fieldTypeFormatter(t))

export function parseFieldType(s: string): FieldType | null {
    switch (s) {
        case "正常異常":
            return FieldType.NormalAbnormal
        case "數值":
            return FieldType.Number
        case "選擇":
            return FieldType.Select
        case "是否":
            return FieldType.YesNo
        case "文字":
            return FieldType.Text
        case "多行文字":
            return FieldType.MutilineText
        default:
            return null
    }
}

export function fieldTypeFormatter(value: FieldType) {
    switch (value) {
        case FieldType.NormalAbnormal:
            return "正常異常"
        case FieldType.Number:
            return "數值"
        case FieldType.Select:
            return "選擇"
        case FieldType.YesNo:
            return "是否"
        case FieldType.Text:
            return "文字"
        case FieldType.MutilineText:
            return "多行文字"
        default:
            return "不明"
    }
}

export type FieldValue = null | boolean | number | string

export type FieldValueNormalAbnormal = "NORMAL" | "ABNORMAL"

export function parseNormalAbnormal(
    s: string
): FieldValueNormalAbnormal | null {
    switch (s) {
        case "正常":
            return "NORMAL"
        case "異常":
            return "ABNORMAL"
        default:
            return null
    }
}

export function normalAbnormalFormatter(
    value: FieldValueNormalAbnormal
): string {
    switch (value) {
        case "ABNORMAL":
            return "異常"
        case "NORMAL":
            return "正常"
        default:
            return ""
    }
}

/** 任務範本DTO */
export interface MissionTemplateDTO {
    id: string
    type: MissionType
    subject: string
    description: string
    duration: number
    managerIds: string[]
    assigneeIds: string[]
    cooperatorIds: string[]
    missionFieldTemplates: MissionFieldTemplateDTO[] | null
    missionSignatureFieldTemplates: MissionSignatureFieldTemplate[]
}

export type MissionTemplateViewModel = MissionTemplateDTO & MissionRuleViewModel

/** 任務工項DTO */
export interface MissionFieldTemplateDTO {
    sortIndex: number
    fieldTemplateId: string | null
    spaceObjectId: string | null
    subject: string
    description: string
    type: FieldType
    selectOptions: string[]
    defaultValue: FieldValue
}

export interface MissionSignatureFieldTemplate {
    title: string
    acceptSignerIdentity: string
}

/** 查詢任務範本 */
export function getMissionTemplates(
    query: {
        skip?: number | null
        take?: number | null
        ids?: string[] | null
        keyword?: string | null
        withFields?: boolean
    } = {}
) {
    return api
        .get("api/MissionTemplates", {
            searchParams: buildParms(query),
        })
        .json<{
            total: number
            items: MissionTemplateDTO[]
        }>()
}

/** 取得任務範本 */
export function getMissionTemplate(id: string) {
    return api.get(`api/MissionTemplates/${id}`).json<MissionTemplateDTO>()
}

/** 設定任務範本參數 */
export interface PutMissionTemplateInput {
    id?: string | null
    type: MissionType
    subject: string
    description: string
    duration?: number
    managerIds?: string[]
    cooperatorIds?: string[]
    assigneeIds?: string[]
    missionFieldTemplates?: {
        sortIndex: number
        fieldTemplateId?: string | null
        spaceObjectId?: string | null
        subject: string
        description: string
        type: FieldType
        selectOptions?: string[]
        defaultValue: FieldValue
    }[]
    missionSignatureFieldTemplates?: {
        title: string
        acceptSignerIdentity: string
    }[]
}

/** 設定任務範本 */
export function putMissionTemplate(input: PutMissionTemplateInput) {
    return api
        .put(`api/MissionTemplates`, {
            json: input,
        })
        .json<MissionTemplateDTO>()
}

/** 刪除任務範本 */
export function deleteMissionTemplate(id: string) {
    return api.delete(`api/MissionTemplates/${id}`)
}

/** 匯出任務範本 */
export async function exportMissionTemplates(query: {
    ids?: string[] | null
    keyword?: string | null
}) {
    const { items: missionTemplates } = await getMissionTemplates({
        ids: query.ids,
        keyword: query.keyword,
        withFields: true,
    })

    const userIds = uniq(
        missionTemplates.flatMap((item) =>
            item.managerIds
                .concat(item.assigneeIds)
                .concat(item.cooperatorIds)
                .concat(
                    item.missionSignatureFieldTemplates.map(
                        (t) => t.acceptSignerIdentity
                    )
                )
        )
    )

    const { items: users } = await getUsers({
        ids: userIds,
    })

    const userMap = new Map(users.map((u) => [u.id, u.account]))

    const missionTemplateRows = missionTemplates.map((item) => [
        item.id,
        missionTypeFormatter(item.type),
        item.subject,
        item.description,
        item.duration,
        item.managerIds.map((id) => userMap.get(id)!).join(", "),
        item.assigneeIds.map((id) => userMap.get(id)!).join(", "),
        item.cooperatorIds.map((id) => userMap.get(id)!).join(", "),
    ])
    const book = xlsx.utils.book_new()
    const missionSheet = xlsx.utils.aoa_to_sheet([
        [
            "ID",
            "類型",
            "主旨",
            "描述",
            "時長（分鐘）",
            "管理人員",
            "受指派人員",
            "協作人員",
        ],
        ...missionTemplateRows,
    ])
    autofitColumns(missionSheet)
    xlsx.utils.book_append_sheet(book, missionSheet, "任務範本")

    function defaultValueFormatter(field: MissionFieldTemplateDTO) {
        switch (field.type) {
            case FieldType.NormalAbnormal:
                return normalAbnormalFormatter(
                    field.defaultValue as FieldValueNormalAbnormal
                )
            default:
                return field.defaultValue!.toString()
        }
    }

    const missionFieldTemplateRows = missionTemplates.flatMap((mission) =>
        mission.missionFieldTemplates!.map((field) => [
            mission.id,
            field.sortIndex,
            field.spaceObjectId,
            field.subject,
            field.description,
            fieldTypeFormatter(field.type),
            field.selectOptions.length ? field.selectOptions.join(", ") : "",
            defaultValueFormatter(field),
        ])
    )
    const fieldSheet = xlsx.utils.aoa_to_sheet([
        [
            "任務範本ID",
            "工項順序",
            "空間物件ID",
            "主旨",
            "描述",
            "工項類型",
            "選擇選項",
            "預設值",
        ],
        ...missionFieldTemplateRows,
    ])
    autofitColumns(fieldSheet)
    xlsx.utils.book_append_sheet(book, fieldSheet, "任務工項範本")

    const signFieldRows = missionTemplates.flatMap((mission) =>
        mission.missionSignatureFieldTemplates.map((s) => [
            mission.id,
            s.title,
            userMap.get(s.acceptSignerIdentity)!,
        ])
    )

    const signFieldSheet = xlsx.utils.aoa_to_sheet([
        ["任務範本ID", "簽名頭銜", "接受的簽名身份"],
        ...signFieldRows,
    ])
    autofitColumns(signFieldSheet)
    xlsx.utils.book_append_sheet(book, signFieldSheet, "任務簽名欄範本")

    xlsx.writeFile(
        book,
        `任務範本清單_${dayjs().format("YYYYMMDDHHmmss")}.xlsx`
    )
}

/** 檢查任務範本空間設備有效性 */
export function checkMissionTemplateRelatePlaceVaild() {
    return api
        .get(`api/MissionTemplates/CheckMissionTemplateRelatePlaceVaild`)
        .json<CheckMissionTemplateRelatePlaceVaildResult>()
}

export interface CheckMissionTemplateRelatePlaceVaildResult {
    invaildFields: InvaildPlaceField[]
}

export interface InvaildPlaceField {
    templateId: string
    templateSubject: string
    sortIndex: number
    subject: string
    spaceObjectId: string
    spaceObjectName: string
    spaceObjectNumber: string
    spaceObjectNamePath: string[]
}
