












import { getUsers, userCache } from "@/services/UsersClient"
import { defineComponent } from "@vue/composition-api"
import PageingSelect from "./PagingSelect.vue"
export default defineComponent({
    name: "UserPagingSelect",
    components: {
        PageingSelect,
    },
    props: {
        value: {
            type: [Array as () => string[], String],
            default: null,
        },
        label: String,
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        clearable: Boolean,
        inIdentity: String,
    },
    setup(props, { emit }) {
        async function userIdSearch(ids: string[]) {
            await userCache.updateByIds(ids)

            return ids.map((id) => {
                const user = userCache.get(id)
                return {
                    value: id,
                    text: `${user?.name} (${user?.account})`,
                }
            })
        }

        async function userKeywordSearch(
            keyword: string,
            skip: number,
            take: number
        ) {
            const { total, items } = await getUsers({
                keyword,
                skip,
                take,
                inIdentity: props.inIdentity,
                notDisabled: true,
            })

            return {
                total,
                items: items.map((item) => ({
                    value: item.id,
                    text: `${item.name} (${item.account})`,
                })),
            }
        }

        function valueInput(value: string[] | string) {
            emit("input", value)
        }

        return {
            userIdSearch,
            userKeywordSearch,
            valueInput,
        }
    },
})
