import ky from "ky"
import { accessToken } from "@/services/AuthService"
import { env } from "@/env"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog
} from "@/services/DialogService"
import { login } from "@/services/AuthService"

const api = ky.create({
    prefixUrl: env.MISSION_SERVICE,
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set(
                    "Authorization",
                    `Bearer ${accessToken.value}`
                )
            }
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if (response.status === 401) {
                    const { button } = await errorDialog(
                        "身份驗證失敗，請重新登入",
                        undefined,
                        DialogButtonType.YesNo
                    )
                    if (button === ButtonResult.Yes) login()
                }
            }
        ]
    }
})

/** 循環排程類型 */
export enum RecurrenceType {
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}

/** 月循環模式 */
export enum MonthMode {
    DaysInMonth = 0,
    OrderedDayOfWeek = 1
}

/** 星期 */
export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export function dayOfWeekFormatter(value: DayOfWeek): string {
    switch (value) {
        case DayOfWeek.Sunday:
            return "星期日"
        case DayOfWeek.Monday:
            return "星期一"
        case DayOfWeek.Tuesday:
            return "星期二"
        case DayOfWeek.Wednesday:
            return "星期三"
        case DayOfWeek.Thursday:
            return "星期四"
        case DayOfWeek.Friday:
            return "星期五"
        case DayOfWeek.Saturday:
            return "星期六"
        default:
            return ""
    }
}

/** 週次星期 */
export interface OrderedDayOfWeek {
    order: number
    dayOfWeek: DayOfWeek
}

export interface RecurrenceSetting {
    /** 循環類型 */
    recurrenceType?: RecurrenceType | null
    /** 循環週期 */
    interval?: number | null
    /** 循環截止時間 */
    endTime?: string | null
    /** 每日發生時間（分） */
    timesInDay?: number[] | null
    /** 每週發生星期 */
    dayOfWeeks?: DayOfWeek[] | null
    /** 月循環週期使用模式 */
    monthMode?: MonthMode
    /** 每月發生日 */
    daysInMonth?: number[] | null
    /** 每月發生週次 */
    orderedDayOfWeek?: OrderedDayOfWeek[] | null
}

export function numberToOrderedDayOfWeek(value: number): OrderedDayOfWeek {
    const order = Math.floor(value / 7)
    const dayOfWeek: DayOfWeek = value % 7
    return {
        order,
        dayOfWeek
    }
}

export function orderedDayOfWeekFormatter({
    order,
    dayOfWeek
}: OrderedDayOfWeek) {
    return `第 ${order + 1} 個${dayOfWeekFormatter(dayOfWeek)}`
}

export interface RecurrenceRuleSetting {
    /** 循環類型 */
    type: RecurrenceType
    /** 循環週期 */
    interval: number
    /** 循環起始時間 */
    startTime: string
    /** 循環截止時間 */
    endTime: string
    /** 每日發生時間（分） */
    timesInDay?: number[] | null
    /** 每週發生星期 */
    dayOfWeeks?: DayOfWeek[] | null
    /** 月循環週期使用模式 */
    monthMode?: MonthMode
    /** 每月發生日 */
    daysInMonth?: number[] | null
    /** 每月發生週次 */
    orderedDayOfWeek?: OrderedDayOfWeek[] | null
}

export function calculateRecurrence(input: {
    setting: RecurrenceRuleSetting
    baseTime?: string
    endTime?: string
    take?: number
}) {
    return api
        .post("api/RecurrenceRuleCalculator/", {
            json: input
        })
        .json<string[]>()
}
