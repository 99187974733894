import ky from "ky"
import { accessToken, userProfile } from "@/services/AuthService"
import { env } from "@/env"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
} from "@/services/DialogService"
import { login } from "@/services/AuthService"
import { buildParms } from "@/utilities/SearchParmsBuilder"
import {
    DayOfWeek,
    MonthMode,
    OrderedDayOfWeek,
    RecurrenceType,
} from "@/utilities/Recurrence"
import {
    FieldType,
    FieldValue,
    FieldValueNormalAbnormal,
} from "./MissionTemplatesService"
import { userCache, UserDTO } from "./UsersClient"
import { computed, reactive } from "@vue/composition-api"
import { SpaceObjectCache, spaceObjectFormatter } from "./SpaceObjectsService"
import dayjs from "dayjs"
import { now } from "./TimeService"
import lodash from "lodash"
import { v4 as uuidv4, v1 as uuidv1 } from "uuid"
import { blobToSha256 } from "@/utilities/Blob"
import contentDisposition from "content-disposition"
import { saveAs } from "file-saver"

const api = ky.create({
    prefixUrl: env.MISSION_SERVICE,
    timeout: 30000,
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set(
                    "Authorization",
                    `Bearer ${accessToken.value}`
                )
            },
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if (response.status === 401) {
                    const { button } = await errorDialog(
                        "身份驗證失敗，請重新登入",
                        undefined,
                        DialogButtonType.YesNo
                    )
                    if (button === ButtonResult.Yes) login()
                }
            },
        ],
    },
})

/** 任務類型 */
export enum MissionType {
    Inspect,
    Repair,
    Upkeep,
    Check,
}

export const missionTypes: MissionType[] = Object.values(MissionType).filter(
    (v) => typeof v !== "string"
) as MissionType[]

export function missionTypeFormatter(type: MissionType) {
    switch (type) {
        case MissionType.Inspect:
            return "巡檢"
        case MissionType.Repair:
            return "維修"
        case MissionType.Upkeep:
            return "保養"
        case MissionType.Check:
            return "查驗"
        default:
            return "不明"
    }
}

/** 解析任務類型 */
export function parseMissionType(s: string): MissionType | null {
    switch (s) {
        case "巡檢":
            return MissionType.Inspect
        case "維修":
            return MissionType.Repair
        case "保養":
            return MissionType.Upkeep
        case "查驗":
            return MissionType.Check
        default:
            return null
    }
}

export enum MissionState {
    Opened,
    Closed,
}

export function missionStateFormatter(state: MissionState) {
    switch (state) {
        case MissionState.Opened:
            return "開放"
        case MissionState.Closed:
            return "關閉"
        default:
            return "不明"
    }
}

/** 任務 */
export interface MissionDTO {
    id: string
    createdGroupId: string | null
    createdTime: string
    updatedTime: string
    type: MissionType
    subject: string
    state: MissionState
    startTime: string
    overdueTime: string
    closedTime: string | null
    description: string
    resultContent: string
    managerIds: string[]
    assigneeIds: string[]
    cooperatorIds: string[]
    missionFields: MissionFieldDTO[]
    missionSignatureFields: MissionSignatureField[]
    creatorId: string
    closerId: string | null
}

export interface MissionRuleViewModel {
    managers?: (UserDTO | null)[]
    assignees?: (UserDTO | null)[]
    cooperators?: (UserDTO | null)[]
}
export type MissionTimeInfo = "UNSTART" | "STARTED" | "OVERDUE" | "CLOSED"
export interface MissionComputedInfo {
    timeInfo: MissionTimeInfo
    timeInfoClass: string
    isManager: boolean
    isAssignee: boolean
    isCooperator: boolean
    /** 可以異動任務基本資料或刪除 */
    canModify: boolean
    canClose: boolean
    canFill: boolean
    filledFields: number
    allFieldFilled: boolean
}

export type MissionViewModel = MissionDTO & MissionRuleViewModel
export type MissionViewModelWithComputedInfo = MissionViewModel &
    MissionComputedInfo

/** 任務工項 */
export interface MissionFieldDTO {
    id: string
    missionId: string
    fieldTemplateId: string | null
    sortIndex: number
    spaceObjectId: string | null
    subject: string
    description: string
    type: FieldType
    selectOptions: string[]
    defaultValue: FieldValue
    latestRecord: FieldRecordDTO | null
}

/** 工項記錄 */
export interface FieldRecordDTO {
    id: string
    missionFieldId: string
    createTime: string
    filledTime: string
    expired: boolean
    value: FieldValue
    fillerId: string
    abnormal: boolean
    memo: string
    pictureIds: string[]
    attachInfos: AttachInfo[]
}

export interface AttachInfo {
    id: string
    fileName: string
    description: string
}

export interface MissionSignatureField {
    id: string
    missionId: string
    sortIndex: number
    title: string
    acceptSignerIdentity: string
    missionSignatureRecords: MissionSignatureRecord[]
}

export interface MissionSignatureRecord {
    id: string
    missionSignatureFieldId: string
    signerId: string
    signPictureId: string
    signedTime: string
}

export enum MissionSortMethod {
    Id,
    Subject,
    StartTime,
    StartTimeDesc,
    OverdueTime,
    OverdueTimeDesc,
}

/** 查詢任務 */
export function getMissions(
    query: {
        skip?: number | null
        take?: number | null
        ids?: string[] | null
        keyword?: string | null
        hasRoleOnly?: boolean | null
        withFields?: boolean
        withSignatureFields?: boolean
        states?: MissionState[]
        workTimeStart?: string | null
        workTimeEnd?: string | null
        startTimeStart?: string | null
        startTimeEnd?: string | null
        overdueTimeStart?: string | null
        overdueTimeEnd?: string | null
        managerIds?: string[]
        assigneeIds?: string[]
        cooperatorIds?: string[]
        sort?: MissionSortMethod | null
    } = {}
) {
    return api
        .get("api/Missions", {
            searchParams: buildParms(query),
        })
        .json<{
            total: number
            items: MissionDTO[]
        }>()
}

/** 取得任務 */
export function getMission(id: string) {
    return api.get(`api/Missions/${id}`).json<MissionDTO>()
}

/** 新增任務參數 */
interface PostMissionInput {
    type: MissionType
    subject: string
    startTime: string
    overdueTime: string
    /** 循環類型 */
    recurrenceType?: RecurrenceType | null
    /** 循環週期 */
    interval?: number | null
    /** 循環截止時間 */
    endTime?: string | null
    /** 每日發生時間（分） */
    timesInDay?: number[] | null
    /** 每週發生星期 */
    dayOfWeeks?: DayOfWeek[] | null
    /** 月循環模式 */
    monthMode?: MonthMode | null
    /** 每月發生日 */
    daysInMonth?: number[] | null
    /** 每月發生週次 */
    orderedDayOfWeek?: OrderedDayOfWeek[] | null
    description: string
    managerIds: string[]
    assigneeIds: string[]
    cooperatorIds: string[]
    fields: PostMissionField[]
    signatureFields: {
        title: string
        acceptSignerIdentity: string
    }[]
}

/** 異動任務基本資訊參數 */
interface PutMissionBaseInfoInput {
    subject: string
    startTime: string
    overdueTime: string
    description: string
    managerIds: string[]
    assigneeIds: string[]
    cooperatorIds: string[]
}

interface PostMissionField {
    fieldTemplateId?: string | null
    sortIndex: number
    spaceObjectId?: string | null
    subject: string
    description: string
    type: FieldType
    selectOptions: string[]
    defaultValue: FieldValue
}

/** 新增任務 */
export function postMission(input: PostMissionInput) {
    return api
        .post(`api/Missions`, {
            json: input,
        })
        .json<string[]>()
}

/** 刪除單一任務 */
export function deleteMission(id: string) {
    return api.delete(`api/Missions/${id}`)
}

/** 異動任務基本資料 */
export function putMissionBaseInfo(
    missionId: string,
    input: PutMissionBaseInfoInput
) {
    return api
        .put(`api/Missions/${missionId}/BaseInfo`, {
            json: input,
        })
        .json<MissionDTO>()
}

/** 刪除複數任務 */
export function deleteMissions(filter: { createdGroupId: string }) {
    return api.delete("api/Missions", {
        json: filter,
    })
}

export type MissionFieldExtend = {
    spaceObject?: string | null
    value: FieldValue
    fillTime: string | null
    abnormal: boolean
    memo: string
    uploadPictures: File[]
    removePictureIds: string[]
    uploadAttaches: File[]
    removeAttachIds: string[]
}

export type MissionFieldViewModel = MissionFieldDTO & MissionFieldExtend

/**任務檢視 model */
export class MissionDetialModel {
    id: string
    private state = reactive({
        mission: null as MissionViewModel | null,
        missionFields: null as MissionFieldViewModel[] | null,
        changed: false,
    })

    mission = computed<MissionViewModelWithComputedInfo | null>(() =>
        this.state.mission ? missionAddComputedInfo(this.state.mission) : null
    )
    missionFields = computed(() => this.state.missionFields)
    changed = computed(() => this.state.changed)
    fieldMap = new Map<string, MissionFieldViewModel>()

    constructor(id: string) {
        this.id = id
        this.load()
    }

    async load() {
        const mission: MissionViewModel = await getMission(this.id)
        mission.managers = undefined
        mission.assignees = undefined
        mission.cooperators = undefined

        const fields: MissionFieldViewModel[] = mission.missionFields.map((f) =>
            Object.assign<MissionFieldDTO, MissionFieldExtend>(f, {
                spaceObject: f.spaceObjectId ? undefined : null,
                value: f.latestRecord?.value ?? null,
                fillTime: null,
                abnormal: f.latestRecord?.abnormal ?? false,
                memo: f.latestRecord?.memo ?? "",
                uploadPictures: [],
                removePictureIds: [],
                uploadAttaches: [],
                removeAttachIds: [],
            })
        )
        this.fieldMap = new Map(fields.map((f) => [f.id, f]))

        this.state.mission = mission
        this.state.missionFields = fields
        this.state.changed = false

        await userCache.updateByIds(
            mission.managerIds
                .concat(mission.assigneeIds)
                .concat(mission.cooperatorIds)
        )

        mission.managers = mission.managerIds.map(
            (id) => userCache.get(id) ?? null
        )
        mission.assignees = mission.assigneeIds.map(
            (id) => userCache.get(id) ?? null
        )
        mission.cooperators = mission.cooperatorIds.map(
            (id) => userCache.get(id) ?? null
        )

        await SpaceObjectCache.updateByIds(
            fields.map((f) => f.spaceObjectId).filter((id) => !!id) as string[]
        )

        fields.forEach((f) => {
            if (!f.spaceObjectId) return
            const found = SpaceObjectCache.get(f.spaceObjectId)
            f.spaceObject = spaceObjectFormatter(found)
        })
    }

    private innerSetField(
        field: MissionFieldViewModel,
        input: {
            value?: FieldValue
            abnormal?: boolean
            uploadPictures?: File[]
            removePictureIds?: string[]
            memo?: string
            uploadAttaches?: File[]
            removeAttachIds?: string[]
        }
    ) {
        field.value = input.value ?? field.value
        field.abnormal = input.abnormal ?? field.abnormal
        field.fillTime = dayjs().toISOString()
        field.uploadPictures = input.uploadPictures ?? field.uploadPictures
        field.uploadAttaches = input.uploadAttaches ?? field.uploadAttaches
        field.memo = input.memo ?? field.memo
        field.removePictureIds =
            input.removePictureIds ?? field.removePictureIds
        field.removeAttachIds = input.removeAttachIds ?? field.removePictureIds
        this.state.changed = true
    }

    setAllFieldDefaultValue() {
        this.state.missionFields?.forEach((f) => {
            this.innerSetField(f, {
                value: f.defaultValue,
            })
        })
    }

    setDefaultValueBySpaceObjectId(id: string) {
        this.state.missionFields?.forEach((f) => {
            if (f.spaceObjectId !== id) return
            this.innerSetField(f, {
                value: f.defaultValue,
            })
        })
    }

    setFieldValue(fieldId: string, value: FieldValue) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            value,
            abnormal:
                found.type === FieldType.NormalAbnormal
                    ? (value as FieldValueNormalAbnormal) === "ABNORMAL"
                        ? true
                        : false
                    : undefined,
        })
    }

    setFieldAbnormal(fieldId: string, abnormal: boolean) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            value:
                found.type === FieldType.NormalAbnormal
                    ? abnormal === true
                        ? "ABNORMAL"
                        : "NORMAL"
                    : undefined,
            abnormal,
        })
    }

    setFieldMemo(fieldId: string, memo: string) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            memo,
        })
    }

    setFieldUploadPictures(fieldId: string, pictures: File[]) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            uploadPictures: pictures,
        })
    }

    setFieldUploadAttaches(fieldId: string, attaches: File[]) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            uploadAttaches: attaches,
        })
    }

    setFieldRemovePictureIds(fieldId: string, removePictureIds: string[]) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            removePictureIds,
        })
    }

    setFieldRemoveAttachIds(fieldId: string, removeAttachIds: string[]) {
        const found = this.fieldMap.get(fieldId)
        if (!found) return
        this.innerSetField(found, {
            removeAttachIds,
        })
    }

    async submitRecords(progressCallBack: (message: string) => any) {
        if (!this.state.missionFields) return
        const fillingFields = this.state.missionFields
            .filter((f) => !!f.fillTime)
            .map((f) => {
                const uploadPictures = f.uploadPictures.map((p) => ({
                    id: uuidv4(),
                    file: p,
                }))

                const uploadAttaches = f.uploadAttaches.map((f) => ({
                    id: uuidv1() + uuidv4(),
                    file: f,
                }))

                const uploadAttachInfos = uploadAttaches.map(
                    (a) =>
                        <AttachInfo>{
                            id: a.id,
                            fileName: a.file.name,
                            description: a.file.name, //TODO:使用者輸入描述
                        }
                )

                return {
                    record: <FieldRecordInput>{
                        missionFieldId: f.id,
                        latestRecordId: f.latestRecord?.id,
                        filledTime: f.fillTime!,
                        value: f.value,
                        abnormal: f.abnormal,
                        memo: f.memo,
                        pictureUploadIds: uploadPictures.map((u) => u.id),
                        removePictureIds: f.removePictureIds,
                        uploadAttachInfos: uploadAttachInfos,
                        removeAttachIds: f.removeAttachIds,
                    },
                    uploadPictures,
                    uploadAttaches,
                }
            })
        progressCallBack("正在傳輸填答結果")
        await postMissionFieldRecords(this.id, {
            mode: PostMissionFieldRecordsMode.Override,
            fieldRecords: fillingFields.map((f) => f.record),
        })
        const allUploadFiles = fillingFields.flatMap((f) =>
            f.uploadPictures.concat(f.uploadAttaches)
        )
        for (let index = 0; index < allUploadFiles.length; index++) {
            const uploadFile = allUploadFiles[index]
            progressCallBack(`正在傳輸檔案 ${uploadFile.file.name}`)
            await uploadAttach(uploadFile.id, uploadFile.file)
        }
    }
}

export enum PostMissionFieldRecordsMode {
    Override = 0,
    RejectConflict = 1,
    FillEmptyOnly = 2,
    UseLatest = 3,
}

export interface FieldRecordInput {
    missionFieldId: string
    latestRecordId?: string | null
    filledTime: string
    value: FieldValue
    abnormal: boolean
    memo: string
    pictureUploadIds: string[]
    removePictureIds: string[]
    uploadAttachInfos: AttachInfo[]
    removeAttachIds: string[]
}

/** 新增任務工項記錄 */
export function postMissionFieldRecords(
    missionId: string,
    input: {
        mode: PostMissionFieldRecordsMode
        fieldRecords: FieldRecordInput[]
    }
) {
    return api.post(`api/Missions/${missionId}/MissionFields/FieldRecords`, {
        json: input,
    })
}

/** 上傳夾檔 */
export async function uploadAttach(id: string, file: File) {
    const sha256 = await blobToSha256(file)
    const formData = new FormData()
    formData.append("file", file)
    formData.append("sha256", sha256)
    return await api.post(`api/Attaches/${id}`, {
        body: formData,
    })
}

export function getMissionFieldPicture(
    missionId: string,
    fieldId: string,
    pictureId: string
) {
    return api.get(
        `api/Missions/${missionId}/MissionFields/${fieldId}/LatestRecord/Pictures/${pictureId}`
    )
}

export function getMissionSignaturePicture(
    missionId: string,
    fieldId: string,
    recordId: string
) {
    return api.get(
        `api/Missions/${missionId}/SignatureFields/${fieldId}/Records/${recordId}/Picture`
    )
}

export function removeSignature(missionId: string, recordId: string) {
    return api.delete(`api/Missions/${missionId}/RemoveSignature`, {
        searchParams: {
            recordId,
        },
    })
}

/** 關閉任務 */
export function closeMission(missionId: string) {
    return api.post(`api/Missions/${missionId}/Close`).json<MissionDTO>()
}

export function missionTimeInfoFormatter(info: MissionTimeInfo): string {
    switch (info) {
        case "CLOSED":
            return "已關閉"
        case "STARTED":
            return "已開始"
        case "UNSTART":
            return "未開始"
        case "OVERDUE":
            return "已逾期"
        default:
            return "--"
    }
}

export function missionAddComputedInfo<TMission extends MissionDTO>(
    mission: TMission
): TMission & MissionComputedInfo {
    const startTime = dayjs(mission.startTime).valueOf()
    const overdueTime = dayjs(mission.overdueTime).valueOf()
    const info: MissionTimeInfo = (() => {
        if (mission.state === MissionState.Closed) return "CLOSED"
        else if (startTime > now.value) return "UNSTART"
        else if (overdueTime > now.value) return "STARTED"
        return "OVERDUE"
    })()

    const timeInfoClass = (() => {
        switch (info) {
            case "UNSTART":
            case "CLOSED":
                return "text--secondary"
            case "OVERDUE":
                return "error--text"
            default:
                return ""
        }
    })()

    const isManager = lodash(mission.managerIds)
        .intersection(userProfile.value?.ids ?? [])
        .some()
    const isAssignee = lodash(mission.assigneeIds)
        .intersection(userProfile.value?.ids ?? [])
        .some()
    const isCooperator = lodash(mission.cooperatorIds)
        .intersection(userProfile.value?.ids ?? [])
        .some()
    const canModify = mission.state !== MissionState.Closed && isManager
    const canClose =
        mission.state !== MissionState.Closed && (isManager || isAssignee)
    const canFill =
        mission.state !== MissionState.Closed &&
        (isManager || isAssignee || isCooperator)

    const filledFields = mission.missionFields.filter(
        (f) =>
            f.latestRecord?.value !== null &&
            f.latestRecord?.value !== undefined
    ).length

    const allFieldFilled = mission.missionFields.every(
        (f) => f.latestRecord && f.latestRecord !== null
    )
    return Object.assign(mission, {
        timeInfo: info!,
        timeInfoClass,
        isManager,
        isAssignee,
        isCooperator,
        canModify,
        canClose,
        canFill,
        filledFields,
        allFieldFilled,
    })
}

/** 下載任務報表 */
export async function makeMissionReport(id: string, templateId: string) {
    const response = await api.post(`api/Missions/${id}/Report`, {
        searchParams: {
            templateId,
        },
    })

    const name = contentDisposition.parse(
        response.headers.get("content-disposition")!
    ).parameters.filename
    const blob = await response.blob()
    saveAs(blob, name)
}

export async function getMissionReportTemplates() {
    return api.get(`api/Missions/api/MissionReportTemplates`).json<
        {
            id: string
            filename: string
        }[]
    >()
}

export async function postMissionReportTemplate(file: File) {
    const formData = new FormData()
    formData.append("file", file)

    return api.post(`api/Missions/api/MissionReportTemplates`, {
        body: formData,
    })
}

export async function deleteMissionReportTemplate(id: string) {
    return api.delete(`api/Missions/api/MissionReportTemplates/${id}`)
}

export async function downloadMissionReportTemplate(id: string) {
    const response = await api.get(
        `api/Missions/api/MissionReportTemplates/${id}`
    )

    const name = contentDisposition.parse(
        response.headers.get("content-disposition")!
    ).parameters.filename
    const blob = await response.blob()
    saveAs(blob, name)
}
